body {
    font-family: 'Segoe UI', 'Lucida Grande', Verdana, Arial, Helvetica, sans-serif;
    font-size: .875em;
    color: #454545;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

a {
    color: #428bca;
    text-decoration: none;
}

.min-width {
    min-width: 550px; /* Project image size 500px + padding */
}

.grid article {
    display: grid;
    gap: 50px;
}

.grid article > * {
    grid-row: 1;
}

.grid article .grid-body,
.grid article.swap aside {
    grid-column: 1;
}

.grid article aside,
.grid article.swap .grid-body {
    grid-column: 2;
}

.grid article aside {
    text-align: center;
    align-self: center;
}

.grid article aside .carousel,
.grid article aside .carousel img {
    width: 500px;
    margin: auto;
}
