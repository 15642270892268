@media (prefers-reduced-motion: no-preference) {
}

@media (min-width: 1200px) {
}

@media (min-width: 992px) {
}

@media (min-width: 768px) {
}

@media (min-width: 576px) {
    .jumbotron {
        padding: 2.5rem 2rem;
    }
}

@media screen {
}

@media (max-width: 1200px)  {
    .app-projects article {
        display: block;
    }

    .project-title {
        margin-top: 30px;
    }
}

@media (max-width: 991px) {
}

/* Landscape phone to portrait tablet */
@media (max-width: 768px) {
    .app-about.grid article {
        display: block;
    }

    .app-about ul {
        margin-top: 0;
    }

    .app-about ul li {
        display: inline-block;
        margin-left: 30px;
    }

    .app-about ul li:first-of-type {
        margin-left: 0;
    }
}

@media (max-width: 690px) {
}

@media (max-width: 650px) {
}

@media (max-width: 620px) {
}

/* Landscape phones and down */
@media (max-width: 480px) {
}

/* Portrait tablet to landscape and desktop */
@media (min-width: 768px) and (max-width: 980px) {

}

/* Large desktop */
@media (min-width: 1200px) {

}

/* Large desktop */
@media (min-width: 1400px) {
    .container {
        max-width: 1140px;
    }
}
