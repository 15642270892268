.app-header {
    position: relative;
    font-family: 'Segoe UI Light', 'Segoe UI', 'Lucida Grande', Verdana, Arial, Helvetica, sans-serif;
    color: #ccc;
    background-color: rgba(0,0,0,0.8);
}

.app-header.jumbotron {
    border-radius: 0;
    margin-bottom: 0;
}

.app-header h1 {
    font-size: 3rem;
}

.app-header p {
    font-size: 1.3rem;
    margin: 0;
}

.app-header-bg {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    opacity: 0.2;
    z-index: -1;
    /* Japanese cube */
    background-color: #fff;
    background-image: linear-gradient(30deg, #445 12%, transparent 12.5%, transparent 87%, #445 87.5%, #445), linear-gradient(150deg, #445 12%, transparent 12.5%, transparent 87%, #445 87.5%, #445), linear-gradient(30deg, #445 12%, transparent 12.5%, transparent 87%, #445 87.5%, #445), linear-gradient(150deg, #445 12%, transparent 12.5%, transparent 87%, #445 87.5%, #445), linear-gradient(60deg, #99a 25%, transparent 25.5%, transparent 75%, #99a 75%, #99a), linear-gradient(60deg, #99a 25%, transparent 25.5%, transparent 75%, #99a 75%, #99a);
    background-size: 80px 140px;
    background-position: 0 0, 0 0, 40px 70px, 40px 70px, 0 0, 40px 70px;
}

.app-main {
    padding-top: 30px;
    padding-bottom: 50px;
}

.app-main h2 {
    font-size: 2.2rem;
    margin: 40px 0 20px;
    padding-bottom: 18px;
    border-bottom: 1px solid #eee;
}

.app-footer {
    margin-top: 20px;
    margin-bottom: 30px;
    color: #555;
    font-size: 12px;
    word-spacing: 1px;
    text-align: right;
}

.app-footer span {
    padding: 5px;
}

.app-about ul {
    list-style: none;
    padding-left: 0;
    margin-top: -10px
}

.app-about ul li {
    line-height: 40px;
}

.app-about ul li svg {
    font-size: 1.5em;
    margin-right: 8px;
}

.app-about ul li a:hover,
.app-about ul li a:focus {
    text-decoration: none;
}

.app-projects article {
    padding-top: 50px;
    padding-bottom: 50px;
    border-bottom: 1px solid #eee;
}

.app-projects article:first-of-type {
    margin-top: 40px;
    border-top: 1px solid #eee;
}

.app-projects article:last-of-type {
    padding-bottom: 0;
    border-bottom: none;
}
