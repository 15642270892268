.app-nav {
    padding: 0;
    line-height: 50px;
    background-color: #222;
    box-shadow: 0px 0px 3px 0px;
    min-height: 3px;
}

.app-nav .navbar-brand {
    margin-right: 0;
    padding: 0 30px 0 0;
    font-size: 18px;
}

.app-nav .navbar-brand,
.app-nav .navbar-brand:focus,
.app-nav .navbar-nav .nav-link {
    color: #777;
}

.app-nav .navbar-brand:hover,
.app-nav .navbar-nav .nav-link:hover {
    color: #fff;
}

.app-nav .navbar-nav .nav-link {
    padding: 0 15px;
}

.app-nav .navbar-nav .nav-link.active {
    color: #fff;
    background-color: #080808;
}
