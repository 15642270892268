.project-title {
    text-align: center;
    margin-bottom: 30px;
}

.project-title h3 {
    font-size: 2.5rem;
    font-weight: normal;
    color: #555;
    margin-bottom: 6px;
}

.project-title footer {
    font-size: 0.8rem;
    color: #aaa;
}

.project-title footer .project-year {
    font-size: 1.2em;
}

.project-showreel img {
    box-shadow: 0 0 3px #aaa;
    margin: 4px;
}

.project-links {
    text-align: center;
    margin-top: 30px;
}

.project-links a {
    font-size: 1.6em;
    margin: 0 10px;
    display: inline-block;
}

.project-links a:hover,
.project-links a:focus {
    text-decoration: none;
}

.project-links a svg {
    display: inline-block;
    vertical-align: middle;
    margin-right: 7px;
    margin-bottom: 4px;
}

.project-links .version-info {
    font-size: 0.9em;
    color: #aaa;
    margin-top: 5px;
}

.image-gallery {
    width: 502px;
    margin: auto;
}

.image-gallery-thumbnail {
    width: 82px;
}

.image-gallery-icon {
    color: #ccc;
}

.image-gallery-left-nav .image-gallery-svg,
.image-gallery-right-nav .image-gallery-svg {
    height: 72px;
    width: 36px;
}

.project-credits tr td {
    padding-left: 30px;
}
